:root {
    --silvy-primary: #ff5634;
    --silvy-primary-darker: #D6472B;
    --silvy-secondary: #faede4;
    --silvy-grey: #f8f8f8;
    --silvy-black: #1c1c1c;
    --silvy-secondary-green: #76dbb9;
    --silvy-secondary-purple: #7378ff;
    --silvy-secondary-yellow: #ffc946;
    --silvy-secondary-blue: #4681ff;
  }
  